exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-event-support-js": () => import("./../../../src/pages/event-support.js" /* webpackChunkName: "component---src-pages-event-support-js" */),
  "component---src-pages-getfed-cooking-classes-js": () => import("./../../../src/pages/getfed-cooking-classes.js" /* webpackChunkName: "component---src-pages-getfed-cooking-classes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchens-js": () => import("./../../../src/pages/kitchens.js" /* webpackChunkName: "component---src-pages-kitchens-js" */),
  "component---src-pages-meeting-rooms-js": () => import("./../../../src/pages/meeting-rooms.js" /* webpackChunkName: "component---src-pages-meeting-rooms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

